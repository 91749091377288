import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Image, TrailguidePlugin } from "../../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`The lift looks endless as it climbs from Reischach straight onto the cone
like peak of the Kronplatz. We jump into the gondola and are surprised how
quick we get spit out again at the top after 1200 vertical metres.`}</p>
    <br />
    <p>{`A great view into the Dolomites opens up, all the way to the Marmolada.
And also the other side with the main ridge of the alps offers a stunning
view. From here we have the possibility to descent into almost every direction.`}</p>
    <TrailguidePlugin content="lat=46.7471&lng=11.9704&coverage=5" mdxType="TrailguidePlugin" />
    <p className="text-xs mb-16" align="center">
      <p>{`Open these trails directly in the `}<a parentName="p" {...{
          "href": "https://trailguide.net/?pos=13,46.7471,11.9704"
        }}>{`web-app`}</a></p>
    </p>
    <p>{`We decide to begin with the mellow Gassl Flow Trail. An easy, slightly
descending trail with not much obstacles, but lots of turns and waves.`}</p>
    <br />
    <br />
    <h4>{`Gassl Flow Trail`}</h4>
    <p>{`Soon we start to feel our cold legs, because the trail might be easy, but
very fun to ride, so we push ever corner and bump and try to carry as much
speed as possible. It's almost 8km long and we need a break, not to destroy
our legs on the first run.
Find the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/1837"
      }}>{`here`}</a></p>
    <Image src="destinations/kronplatz_2.jpg" mdxType="Image" />
    <br />
    <h4>{`Furcia`}</h4>
    <p>{`Next we drop in to the Furcia Trail. The upper part is nice to us, and lets
us enjoy the view. Then it picks up speed and becomes steeper. Some natural
sections in the lower part have more roots and lines to play with.
Find the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/1165"
      }}>{`here`}</a></p>
    <Image src="destinations/kronplatz_3.jpg" mdxType="Image" />
    <br />
    <h4>{`Herrnsteig`}</h4>
    <p>{`As long as we have enough power, we decide to do the most challenging and
longest descent, the Herrensteig. It claims 8km and over 1200m of descent on
a demanding trail. The trail starts fast on a machine built part with lots of
embedded rocks, then dives into the woods and becomes steeper. Here we find
our absolute favourite parts called Hans and Franz. Some variations of the
main trail. Those are more natural, with roots and steep corners. Awesome!
Find the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/1166"
      }}>{`here`}</a></p>
    <Image src="destinations/kronplatz_4.jpg" mdxType="Image" />
    <br />
    <h4>{`Korer`}</h4>
    <p>{`Also the Korer trail is one of our favourites. Not long, but winds down with
a great rythm through the trees.
Find the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/1838"
      }}>{`here`}</a></p>
    <Image src="destinations/kronplatz_5.jpg" mdxType="Image" />
    <br />
    <h4>{`Weizgruber Trail`}</h4>
    <p>{`Also around the Kronplatz, you finde some really nice trail tours like the
Weizgruber Trail above Sand in Taufers.
Find the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/1839"
      }}>{`here`}</a></p>
    <Image src="destinations/kronplatz_8.jpg" mdxType="Image" />
    <br />
    <h4>{`Durakopf`}</h4>
    <p>{`Or this ridge, down from the Durakopf towards Niederrasen.
Find the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/1840"
      }}>{`here`}</a></p>
    <Image src="destinations/kronplatz_6.jpg" mdxType="Image" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      